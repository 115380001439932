<template>
  <div class="overview-wrapper">
    <div class="side-wrapper">
      <ul class="list-group list-group-flush">
        <a
          v-for="(col, i) in searchOptions"
          :key="i"
          href="#"
          class="list-group-item list-group-item-action flex-column align-items-start side-item"
          @click="sideColClicked(i)"
        >
          {{ col.name }}
        </a>
      </ul>
    </div>
    <div class="content-wrapper">
      <b-breadcrumb :items="crumb_links"></b-breadcrumb>
      <div id="rightcolumn">
        <p>
          The
          <a href="/">Arabidopsis Information Resource (TAIR)</a>
          collects information and maintains a
          <a
            href="https://conf.phoenixbioinformatics.org/display/COM/Education+and+Outreach+Resources"
            target="_blank"
            >database</a
          >
          of genetic and molecular biology
          <a
            href="https://conf.phoenixbioinformatics.org/display/COM/Data+Sources"
            target="_blank"
            >data</a
          >
          for
          <a
            href="https://conf.phoenixbioinformatics.org/display/COM/Education+and+Outreach+Resources"
            target="_blank"
            ><i>Arabidopsis thaliana,</i>
          </a>
          a widely used model plant.
        </p>

        <p>
          TAIR is managed by the nonprofit
          <a href="http://www.phoenixbioinformatics.org/" target="_blank"
            >Phoenix Bioinformatics Corporation</a
          >
          and is supported through institutional, lab and personal
          subscriptions. Prior funding was provided by the National Science
          Foundation (Grant No. DBI-0850219). Please see the
          <a
            href="https://www.phoenixbioinformatics.org/support-orig/tair/#Pricing"
            target="_blank"
            >TAIR subscription page</a
          >
          for information on how to subscribe for full access to all TAIR pages.
        </p>

        <p>
          For an in-depth description of the TAIR project see our
          <a href="/about/tairpubs.jsp">publications.</a>
        </p>
        <p>
          We hope you enjoy using this site. Please
          <a href="/contact">contact</a>
          us if you have any suggestions or questions.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToolsOverview",
  data() {
    return {
      crumb_links: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "About Us",
          active: true,
        },
      ],
      overviewTitle: "About TAIR",
      description:
        "This page provides links to all the analysis and visualization tools implemented at TAIR.",
      searchOptions: [
        {
          name: "TAIR Staff",
          route: "https://conf.phoenixbioinformatics.org/display/COM/Our+Team",
        },
        {
          name: "TAIR data sources",
          route:
            "https://conf.phoenixbioinformatics.org/display/COM/Data+Sources",
        },
        {
          name: "Hyperlinking to TAIR",
          route:
            "https://conf.phoenixbioinformatics.org/display/COM/Hyperlinking+to+TAIR",
        },
        {
          name: "Citing TAIR",
          route:
            "https://conf.phoenixbioinformatics.org/display/COM/Citing+TAIR",
        },
        {
          name: "TAIR Database Schema",
          route: "${process.env.VUE_APP_OLD_TAIR_URL}/search/ERwin/Tair.htm",
        },
        {
          name: "PLAIN Project",
          route:
            "https://conf.phoenixbioinformatics.org/display/COM/PLAIN+Project",
        },
        {
          name: "Publications",
          route:
            "https://conf.phoenixbioinformatics.org/display/COM/TAIR+Publications",
        },
        {
          name: "TAIR Software",
          route:
            "https://conf.phoenixbioinformatics.org/display/COM/TAIR+Software",
        },
        {
          name: "TAIR Presentations (ppt)",
          route:
            "https://conf.phoenixbioinformatics.org/display/COM/TAIR+Presentations",
        },
        {
          name: "Users Guide (pdf)",
          route:
            "https://tair-data.s3.us-west-2.amazonaws.com/download-forms/CPBI_Unit_1.11_2017_submitted.pdf",
        },
        {
          name: "TAIR Survey",
          route:
            "https://conf.phoenixbioinformatics.org/display/COM/TAIR+Surveys",
        },
        {
          name: "TAIR Funding",
          route:
            "https://conf.phoenixbioinformatics.org/display/COM/TAIR+Funding",
        },
        {
          name: "TAIR Licensing",
          route:
            "https://conf.phoenixbioinformatics.org/display/COM/TAIR+Licensing",
        },
        {
          name: "TAIR Terms of Use",
          route:
            "https://conf.phoenixbioinformatics.org/display/COM/TAIR+Terms+of+Use",
        },
      ],
    };
  },
  mounted() {
    document.title = "About Us";
  },
  methods: {
    sideColClicked(index) {
      window.open(this.searchOptions[index].route, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.overview-wrapper {
  display: flex;
  background-color: #f1efec;
  border: 1px solid #e0e0e0;
  padding: 20px;
  .breadcrumb {
    padding: 10px 0;
    font-weight: bold;
    font-size: 18px;
  }
  .side-wrapper {
    width: 20%;
    min-width: 160px;
    border-right: 1px solid #e0e0e0;
    padding-right: 20px;
    .side-item {
      text-align: left;
      padding: 10px 10px;
      border-bottom: 1px solid #e0e0e0;
      font-weight: bold;
      font-size: 20px;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
